import classNames from 'classnames';
import { ReactNode, useState } from 'react';

/* components */
import { Placeholder } from '../Placeholder/Placeholder';
import { CardOptions } from './CardOptions/CardOptions';

interface Props {
  image?: string;
  title?: string;
  options?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const Card = (props: Props) => {
  const { image, title, options, actions, children, className } = props;

  const [showPlaceholder, setShowPlaceholder] = useState(false);

  return (
    <div
      className={classNames(
        'card card-compact bg-neutral-000 shadow',
        className
      )}
    >
      {/* options */}
      {options && (
        <CardOptions className="absolute right-4 top-4">{options}</CardOptions>
      )}
      {/* image */}
      {image && (
        <>
          <figure className="h-40 px-4 pt-4 overflow-hidden">
            {showPlaceholder ? (
              <Placeholder />
            ) : (
              <img
                src={image}
                alt={title}
                onError={() => setShowPlaceholder(true)}
              />
            )}
          </figure>
        </>
      )}
      <div className="card-body">
        {/*  title */}
        {title && (
          <h2 className="card-title max-w-[calc(100%-44px)] text-lg">
            {title}
          </h2>
        )}
        {/* content */}
        {children}
        {/* actions */}
        {actions && <div className="card-actions mt-4">{actions}</div>}
      </div>
    </div>
  );
};
