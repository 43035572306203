import { useState, useEffect, FormEvent } from 'react';

interface Props {
  name: string;
  label?: string;
  preview?: string | File;
}

export const ImageUpload = (props: Props) => {
  const { label, name } = props;

  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  /* create a preview as a side effect, whenever selected file changes */
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    /* free memory when ever this component is unmounted */
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: FormEvent) => {
    const files = (e.target as HTMLInputElement).files;

    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(files[0]);
  };

  return (
    <label className="form-control w-full max-w-xs" htmlFor={name}>
      {/* label */}
      {label && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}
      {/* preview */}
      {typeof props.preview === 'string' && !selectedFile && (
        <img src={props.preview} alt={'preview'} />
      )}
      {selectedFile && <img src={preview} alt={selectedFile.name} />}
      {/* input */}
      <input
        className="input w-full max-w-xs px-0 py-2 focus:border-none focus:outline-none"
        type="file"
        name={name}
        onChange={onSelectFile}
      />
    </label>
  );
};
