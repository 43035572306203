import { useEffect, useState } from 'react';
import classNames from 'classnames';

/* components */
import { Modal } from '../../../../Common/Modal/Modal';
import { Drawer } from '../../../../Common/Drawer/Drawer';
import { VehicleForm } from '../../../../Forms/VehicleForm/VehicleForm';
import { Card } from '../../../../Common/Card/Card';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

/* api */
import api from '../../../../../configs/api';
import {
  deleteVehicle,
  restoreVehicle,
  updateVehicle
} from '../../../../../api/vehicles';

/* types */
import { IVehicle } from '../../../../../types/Vehicle';

interface Props {
  vehicle: IVehicle;
}

export const Vehicle = (props: Props) => {
  const [vehicle, setVehicle] = useState<IVehicle>(props.vehicle);
  const [vehicleType, setVehicleType] = useState<string>();

  /* get the vehicles type */
  useEffect(() => {
    api.get(`/vehicle_types/${props.vehicle.type}`).then((response) => {
      setVehicleType(response.data.name);
    });
  }, [props.vehicle]);

  /* card options */
  const Options = () => {
    return (
      <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-sm btn-ghost">
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu shadow bg-base-100 
                    rounded-lg w-max p-1"
        >
          {/* delete vehicle */}
          <li>
            <button
              className="menu-item"
              onClick={() =>
                (
                  document.getElementById(
                    `delete-vehicle-${vehicle.id}`
                  ) as HTMLDialogElement
                )?.showModal()
              }
            >
              Delete
            </button>
          </li>
        </ul>
      </div>
    );
  };

  /* card action */
  const Actions = () => {
    return (
      <div className="flex gap-2 w-full">
        {/* edit vehicle */}
        {!vehicle.deleted && (
          <Drawer
            id={`edit-vehicle-${vehicle.id}`}
            title="Edit Vehicle"
            trigger={
              <label
                htmlFor={`edit-vehicle-${vehicle.id}`}
                className="drawer-button btn btn-sm btn-primary w-full"
              >
                Edit Vehicle
              </label>
            }
          >
            <VehicleForm
              vehicle={vehicle}
              handleSubmit={(vehicle) =>
                updateVehicle(vehicle).then((vehicle) => {
                  if (vehicle) setVehicle(vehicle);
                })
              }
            />
          </Drawer>
        )}
        {/* restore vehicle */}
        {vehicle.deleted && (
          <Modal
            id={`restore-vehicle-${vehicle.id}`}
            title="Restore Vehicle"
            trigger={<button className="btn btn-sm w-full">Restore</button>}
            action={() =>
              restoreVehicle(vehicle).then((vehicle) => setVehicle(vehicle))
            }
          >
            Are you sure you want to restore this vehicle?
          </Modal>
        )}
      </div>
    );
  };

  return (
    <>
      <Card
        image={vehicle.image?.toString() || 'placeholder'}
        title={`${vehicle.make} ${vehicle.model}`}
        actions={<Actions />}
        options={<Options />}
        className={classNames(vehicle.deleted && 'opacity-60')}
      >
        {/* vehicle details */}
        <div className="text-sm">
          <div>{`${vehicleType}, ${vehicle.displacement}cc, ${vehicle.color}, ${vehicle.year}`}</div>
          <div>{vehicle.registration}</div>
        </div>

        <hr className="border-neutral-200 my-2" />

        {/* vehicle dates */}
        <div className="flex gap-4">
          <div className="text-xs">
            MOT Due <span>--.--.--</span>
          </div>
          <div className="text-xs">
            Service Due <span>--.--.--</span>
          </div>
        </div>
      </Card>
      {/* delete vehicle */}
      <Modal
        id={`delete-vehicle-${vehicle.id}`}
        title="Delete Vehicle"
        action={() =>
          deleteVehicle(vehicle).then((vehicle) => setVehicle(vehicle))
        }
      >
        <div className="flex flex-col gap-4">
          <p>Are you sure you want to delete this vehicle?</p>
        </div>
      </Modal>
    </>
  );
};
