import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
  faSquareXTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Footer = () => {
  return (
    <footer
      className="footer mt-8 lg:p-10 p-6 bg-neutral-000 grid md:grid-cols-5
                2xl:gap-24 xl:gap-20 lg:gap-16 md:gap-12 grid-cols-2
                xs: gap-6 text-xs"
    >
      <aside className="w-full justify-center">
        <div className="flex gap-4">
          {/* All Ride logo */}
          <img
            src={require('../../../images/all_ride.jpg')}
            className="lg:h-12 h-10 w-auto"
            alt="All Ride Logo"
          />
          {/* Able logo */}
          <img
            src={require('../../../images/able.jpg')}
            className="lg:h-12 h-10 w-auto"
            alt="All Ride Logo"
          />
        </div>

        <p className="mt-2 xl:text-xs text-[10px]">
          {' '}
          &copy; All Ride Motorcycle Training
        </p>
      </aside>

      {/* policies */}
      <div className="w-full justify-center md:pl-4">
        <h6 className="footer-title mb-0">Policies</h6>
        <div className="flex flex-col xl:gap-1">
          <a href="/privacy-policy" className="link">
            Privacy Policy
          </a>
          <a href="/cookie-policy" className="link">
            Cookie Policy
          </a>
          <a href="/terms-and-conditions" className="link">
            Terms and Conditions
          </a>
        </div>
      </div>

      <hr className="border border-neutral-100 w-full md:hidden col-span-2" />

      {/* all ride address */}
      <address className="not-italic lg:w-60 w-full md:justify-start justify-center">
        <h6 className="footer-title mb-0">
          All Ride <span className="xl:inline hidden">Motorcycle Training</span>
        </h6>
        <div className="flex flex-col xl:gap-1">
          <p>2 Second Ave</p>
          <p>Midsomer Norton</p>
          <p>Radstock</p>
          <p>BA3 4BE</p>
        </div>
      </address>

      {/* able address */}
      <address className="not-italic lg:w-60 w-full md:justify-start justify-center">
        <h6 className="footer-title mb-0">
          Able <span className="xl:inline hidden">Motorcycle Training</span>
        </h6>
        <div className="flex flex-col xl:gap-1">
          <p>The Clarendon Academy</p>
          <p>Frome Rd</p>
          <p>Trowbridge</p>
          <p>BA14 0DJ</p>
        </div>
      </address>

      {/* contact */}
      <div className="md:col-span-1 col-span-2 w-full">
        <hr className="border border-neutral-100 w-full mb-4 md:hidden" />
        <div className="md:block flex justify-between w-full items-center">
          <h6 className="footer-title mb-2">Contact Us</h6>
          <div className="flex flex-col gap-1">
            {/* phone */}
            <a href="tel:07858 729868" className="link">
              07858 729868
            </a>
            {/* email */}
            <a
              href="mailto:enquiries@allridemotorcycletraining.co.uk"
              className="link"
            >
              <span className="text-neutral-600 flex items-center gap-2">
                <FontAwesomeIcon icon={faEnvelope} className="text-lg" />{' '}
                <span className="underline">Email</span>
              </span>
            </a>
          </div>

          {/* social media */}
          <div className="mt-2">
            <div className="flex items-center gap-4 lg:text-2xl text-xl text-neutral-600">
              {/* facebook */}
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              {/* twitter */}
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary"
              >
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </a>
              {/* instagram */}
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noreferrer"
                className="hover:text-primary"
              >
                <FontAwesomeIcon icon={faInstagramSquare} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
