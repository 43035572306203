import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

/* components */
import { User } from './User/User';

/* hooks */
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

/* configs */
import { loginRequest } from '../../../configs/auth';

/* icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {
  toggleMenu?: () => void;
}

export const Navbar = (props: Props) => {
  const { instance } = useMsal();
  const { width } = useWindowDimensions();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  /* log the user into the msal instance */
  const login = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="navbar bg-neutral-000 fixed shadow h-16 z-50 px-4">
      <div className="navbar-start">
        {/* menu button */}
        <div className="flex-none">
          <button
            onClick={props.toggleMenu}
            className="btn btn-square btn-ghost md:hidden"
          >
            <FontAwesomeIcon icon={faBars} className="w-5 h-5" />
          </button>
        </div>

        {/* logo */}
        <div className={classNames(width > 768 ? 'flex-0' : 'flex-1')}>
          <div className="overflow-hidden h-14 w-24 p-2">
            <img
              src={require('../../../images/all_ride.jpg')}
              className="h-full w-auto"
              alt="All Ride Logo"
            />
          </div>
        </div>

        {/* public navigation */}
        {width > 768 && (
          <UnauthenticatedTemplate>
            <div className="flex-none">
              <ul className="menu menu-horizontal px-1 flex items-center">
                <li>
                  <NavLink to="/" end>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" end>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/courses" end>
                    Our Courses
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq" end>
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" end>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </UnauthenticatedTemplate>
        )}
      </div>

      <div className="navbar-end">
        {/* user / login */}
        <div className="flex-none">
          {isAuthenticated ? (
            <User />
          ) : (
            <div className="flex gap-2">
              {/* book */}
              <button
                className="btn btn-sm btn-primary"
                onClick={() => navigate('/book')}
              >
                Book Now!
              </button>
              {/* login */}
              <button
                className="btn btn-sm btn-ghost"
                onClick={login}
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faUserAlt} className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
