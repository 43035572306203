import { Routes, Route, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';

import { Layout } from './components/Layout/Layout';

/* public */
import { Home } from './components/Layout/Public/Home/Home';

/* private */
import { Dashboard } from './components/Layout/Private/Dashboard/Dashboard';
import { Bookings } from './components/Layout/Private/Bookings/Bookings';
import { Customers } from './components/Layout/Private/Customers/Customers';
import { Calendar } from './components/Layout/Private/Calendar/Calendar';
import { Courses } from './components/Layout/Private/Courses/Courses';
import { Licenses } from './components/Layout/Private/Licenses/Licenses';
import { Locations } from './components/Layout/Private/Locations/Locations';
import { Address } from './components/Layout/Private/Addresses/Addresses';
import { Vehicles } from './components/Layout/Private/Vehicles/Vehicles';
import { Profile } from './components/Layout/Private/Profile/Profile';

const App = () => {
  return (
    <div id="app" className="theme-light font-body font-light text-text">
      {/* unauthenticated */}
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Layout />}>
              <Route index element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>

      {/* authenticated */}
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="bookings" element={<Bookings />} />
              <Route path="customers" element={<Customers />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="courses" element={<Courses />} />
              <Route path="licenses" element={<Licenses />} />
              <Route path="locations" element={<Locations />} />
              <Route path="addresses" element={<Address />} />
              <Route path="vehicles" element={<Vehicles />} />
              <Route path="profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </div>
  );
};

export default App;
